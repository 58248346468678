<template>
  <q-layout view="lHh lpR fFf" class="bg-grey-1">
    <q-header reveal elevated class="bg-white text-grey-8 q-py-xs q-gutter-sm" height-hint="8">
      <q-toolbar>
        <q-space/>
        <q-btn
            flat
            dense
            round
            @click="logout"
            icon="logout"
            toolt
        />
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view/>
    </q-page-container>

  </q-layout>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'MainLayout',
  data() {
    return {
      leftDrawerOpen: false,
      localSuggestions: 10
    }
  },
  methods: {
    ...mapActions('auth', ['logoutUser']),
    async logout() {
      await this.logoutUser()
      await this.$router.push('/login')
    }
  }
}
</script>
